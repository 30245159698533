import { Subject } from 'rxjs';

export default class Loader {
    constructor(path) {
        this.id = crypto.randomUUID();

        this._onLoadProgress = new Subject();
        this.onLoadProgress$ = this._onLoadProgress.asObservable();

        this._onLoaded = new Subject();
        this.onLoaded$ = this._onLoaded.asObservable();

        this.load(path);
    }
    load(path) {
    }

    pipe(value) {
        this.onLoaded$ = this.onLoaded$.pipe(value);
    }
}