import { Matrix4, Quaternion, Vector3 } from 'three';
import CameraMovement from './camera-movement';

export default class CameraReach extends CameraMovement {

    constructor(actor, camera, speed, rotationSpeed) {
        super(speed);
        this._offset = 1.35;
        this._rotationSpeed = rotationSpeed;

        this._rotationMatrix = new Matrix4();
        this._rotationMatrix.lookAt(
            camera.position,
            new Vector3(actor.position.x, camera.position.y, actor.position.z),
            camera.up
        );
        this._targetQuaternion = new Quaternion();
        this._targetQuaternion.setFromRotationMatrix(this._rotationMatrix);

        this._velocity = new Vector3(
            actor.position.x - camera.position.x,
            camera.position.y,
            actor.position.z - camera.position.z,
        ).normalize();

        this._actor = actor;
        this._reached = false;
    }
    update(camera, dt) {
        if (!camera.quaternion.equals(this._targetQuaternion)) {
            camera.quaternion.rotateTowards(this._targetQuaternion, this._rotationSpeed * dt);
        }
        if (this._reached) {
            return;
        }
        this._reached = this._actor.distanceToSquared(camera.position.x, camera.position.y, camera.position.z) < this._offset * this._offset;

        camera.position.x += this._velocity.x * this._speed * dt;
        camera.position.z += this._velocity.z * this._speed * dt;
    }
}