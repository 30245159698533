import { Vector3 } from 'three';
import CameraMovement from './camera-movement';

export default class CameraOrbit extends CameraMovement {

    constructor(speed, r, spread) {
        super(speed);
        this._r = r;
        this._spread = spread;
        this._startAngle = 90;
        this._currentAngle = this._startAngle;
        this._direction = 1;
    }
    update(camera, dt) {
        camera.position.x = this._calculateX();
        camera.position.y = 0.89;
        camera.position.z = this._calculateY();
        camera.lookAt(new Vector3(0, 1, 0));

        this._currentAngle += this._direction * this._speed * dt;

        if (this._direction > 0 && this._currentAngle > this._startAngle + this._spread
            || this._direction < 0 && this._currentAngle < this._startAngle - this._spread) {
            this._direction = -this._direction;
        }
    }

    _calculateX() {
        return this._r * Math.cos(this._currentAngle * Math.PI / 180);
    }
    _calculateY() {
        return this._r * Math.sin(this._currentAngle * Math.PI / 180);
    }
}