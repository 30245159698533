import AudioActor from "./audio-actor";

export default class AudioActorBuilder {
    constructor() {
        this._path = null;
    }
    static start() {
        return new AudioActorBuilder();
    }
    withLoader(path) {
        this._path = path;
        return this;
    }
    build() {
        const actor = new AudioActor();
        if (this._path) {
            actor.addLoader(this._path);
        }
        return actor;
    }
}