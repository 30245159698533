import { forkJoin, mergeMap } from 'rxjs';

import MindAR from './mind-ar';
import World from './world';
import ProgressTracker from './progress-tracker';
import ModelActorBuilder from './model-actor-builder';
import AudioActorBuilder from './audio-actor-builder';

import scannerLogo from '../../assets/img/black-label.png';
import islandModelPath from '../../assets/models/island2.glb'; //19741724
import cocktailGuyModelPath from '../../assets/models/cocktailGuy2.glb'; //1838348
import masterDestillerModelPath from '../../assets/models/masterDestiller2.glb'; //1164864
import selfieGirlModelPath from '../../assets/models/selfieGirl2.glb'; //8286820
import audioPath from '../../assets/audio/jingle.mp3';
import CameraOrbit from './camera-orbit';



document.getElementById('scanner-logo').src = scannerLogo;

const container = document.getElementById('ar');
const loader = document.getElementById('loader');
const scanner = document.getElementById('ar__scanner');

const navbar = document.getElementById('navbar');
navbar.style.position = 'fixed';


const cameraMovement = new CameraOrbit(2, 3, 30);
const world = new World(cameraMovement);

world.onTriggerEnter$.subscribe({
    next: actor => {
        actor.sayHello();
    }
})

showLoader('Getting ready...')

const actors = [
    ModelActorBuilder.start('island')
        .withScale(0.85)
        .withLoader(islandModelPath, 12753660)
        .build(),
    ModelActorBuilder.start('cocktailGuy')
        .withPosition(0.6, 0.44, 0.19)
        .withRotation(0, -25, 0)
        .withScale(0.3)
        .withLoader(cocktailGuyModelPath, 1838348)
        .asTrigger()
        .build(),
    ModelActorBuilder.start('masterDestiller')
        .withPosition(-0.72, 0.42, -0.18)
        .withRotation(0, 10, 0)
        .withScale(0.3)
        .withLoader(masterDestillerModelPath, 1164864)
        .asTrigger()
        .build(),
    ModelActorBuilder.start('selfieGirl')
        .withPosition(-0.52, 0.47, 1.02)
        .withScale(0.3)
        .withLoader(selfieGirlModelPath, 1767936)
        .asTrigger()
        .build(),
    AudioActorBuilder.start().withLoader(audioPath).build(),
];

const tracker = new ProgressTracker(actors.map(actor => actor.loader));
tracker.onProgress$.subscribe({
    next: progress => showLoader(`Loading... <br> ${progress.toFixed(0)}%`),
});
tracker.onComplete$.subscribe({
    complete: () => showLoader(`Getting ready...`)
});

// forkJoin(actors.map(actor => actor.loader.onLoaded$))
//     .subscribe({
//         next: actors => {
//             actors.forEach(actor => actor.addTo(world));
//             hideLoader();

//             document.body.addEventListener('click', (e) => {
//                 const mouseX = (e.clientX / window.innerWidth) * 2 - 1;
//                 const mouseY = -(e.clientY / window.innerHeight) * 2 + 1;
//                 world.checkTriggers(mouseX, mouseY);
//             });
//             world.start();
//         },
//     });

forkJoin(actors.map(actor => actor.loader.onLoaded$))
    .pipe(
        mergeMap(actors => {
            actors.forEach(actor => actor.addTo(world));

            const mindAR = new MindAR(container);
            mindAR.setOnTargetFound(handleTargetFound);
            return mindAR.start();
        })
    )
    .subscribe({
        next: () => {
            container.classList.add('--transparent');

            document.body.addEventListener('click', (e) => {
                const mouseX = (e.clientX / window.innerWidth) * 2 - 1;
                const mouseY = -(e.clientY / window.innerHeight) * 2 + 1;
                world.checkTriggers(mouseX, mouseY);
            });

            hideLoader();
            showScanner();
        },
    });


function handleTargetFound() {
    navbar.classList.add('--hidden');
    hideScanner(false);
    world.start();
}


function showScanner() {
    scanner.classList.remove('--hidden');
}
function hideScanner() {
    scanner.classList.add('--hidden');
}
function showLoader(text) {
    loader.innerHTML = text;
    loader.classList.remove('--hidden');
}
function hideLoader() {
    loader.classList.add('--hidden');
}
