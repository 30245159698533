import { Subject } from 'rxjs';
import { AmbientLight, AnimationMixer, AudioListener, Clock, HemisphereLight, PerspectiveCamera, PointLight, PositionalAudio, Raycaster, Scene, sRGBEncoding, Vector2, Vector3, WebGLRenderer } from 'three';
import CameraReach from './camera-reach';

export default class World {

    constructor(cameraMovement) {
        this._cameraMovement = cameraMovement;

        this._onTriggerEnter = new Subject();
        this.onTriggerEnter$ = this._onTriggerEnter.asObservable();

        this.actors = [];
        this.clock = new Clock();
        this.scene = new Scene();
        this.camera = new PerspectiveCamera(50, window.innerWidth / window.innerHeight, 0.1, 2000);

        this.renderer = new WebGLRenderer({ antialias: !0, alpha: !0 });
        this.renderer.outputEncoding = sRGBEncoding;
        this.renderer.setPixelRatio(window.devicePixelRatio);

        this.renderer.domElement.style.position = 'absolute';
        this.renderer.domElement.style.top = 0;
        this.renderer.setClearColor(0xffffff, 0);
        this.renderer.setSize(window.innerWidth, window.innerHeight);

        window.addEventListener('resize', () => {
            this.camera.aspect = window.innerWidth / window.innerHeight;
            this.camera.updateProjectionMatrix();

            this.renderer.setSize(window.innerWidth, window.innerHeight);
        }, false);

        document.body.appendChild(this.renderer.domElement);

        const lighting = {
            night: {
                ambient: 0x1e00ff,
                hemisphere: {
                    sky: 0x2f03ab,
                    ground: 0xccbb00,
                },
                point: 0xffc35c,
            },
            day: {
                ambient: 0xffffff,
                hemisphere: {
                    sky: 0x7f8add,
                    ground: 0x1b007a,
                },
                point: 0xffffff,
            }
        }
        const lightingSetup = lighting.day;

        this.hemisphereLight = new HemisphereLight(lightingSetup.hemisphere.sky, lightingSetup.hemisphere.ground, 1);
        this.scene.add(this.hemisphereLight);

        this.ambientLight = new AmbientLight(lightingSetup.ambient, 0.4);
        this.scene.add(this.ambientLight);

        this.pointLight = new PointLight(lightingSetup.point, 1, 100);
        this.pointLight.position.set(3, 6, 0);

        this.scene.add(this.pointLight);

        this.mixers = [];
        this.backgroundMusic = null;
    }

    addActor(actor) {
        this.actors.push(actor);
    }
    addAudio(actorAudio) {
        const listener = new AudioListener();
        this.camera.add(listener);

        this.backgroundMusic = new PositionalAudio(listener);
        this.backgroundMusic.setBuffer(actorAudio.audioClip);
        this.backgroundMusic.setLoop(true);
    }

    start() {
        this.actors.forEach(actor => {
            actor.gltf.scene.position.set(actor.position.x, actor.position.y, actor.position.z);
            actor.gltf.scene.rotation.set(actor.rotation.x, actor.rotation.y, actor.rotation.z);
            actor.gltf.scene.scale.set(actor.scale, actor.scale, actor.scale);

            if (actor.gltf.animations.length > 0) {
                const mixer = new AnimationMixer(actor.gltf.scene);
                mixer.clipAction(actor.gltf.animations[0]).play();
                this.mixers.push({
                    id: actor.id,
                    mixer: mixer,
                });
            }
            this.scene.add(actor.gltf.scene);
        });

        this.backgroundMusic.play();

        this.renderer.setAnimationLoop(() => {
            const dt = this.clock.getDelta();
            this._cameraMovement.update(this.camera, dt);

            this.mixers.forEach(entry => entry.mixer.update(dt));
            this.renderer.render(this.scene, this.camera);
        });
    }
    checkTriggers(x, y) {
        const raycaster = new Raycaster();
        raycaster.setFromCamera(new Vector2(x, y), this.camera);

        const hitActors = this.actors
            .filter(actor => actor.isTrigger())
            .filter(
                actor => raycaster.intersectObject(actor.gltf.scene, true).length > 0
            );

        const hit = hitActors.reduce((carry, actor) => {
            const distanceSquared = actor.distanceToSquared(this.camera.position.x, this.camera.position.y, this.camera.position.z);
            if (distanceSquared <= carry.min) {
                carry = {
                    min: distanceSquared,
                    actor: actor,
                };
            }
            return carry;
        }, { min: Number.MAX_SAFE_INTEGER, actor: null });

        if (hit.actor) {
            this.changeCameraMovement(new CameraReach(hit.actor, this.camera, 1.5, 0.6));
        }
    }

    changeCameraMovement(cameraMovement) {
        this._cameraMovement = cameraMovement;
    }
}