import { MindARThree } from 'mind-ar/dist/mindar-image-three.prod.js';
import { from } from 'rxjs';

import imageTarget from '../../assets/fbl.mind';

export default class MindAR {
    constructor(container) {
        this.mindarThree = new MindARThree({
            container: container,
            imageTargetSrc: imageTarget,
            uiScanning: 'no',
            uiLoading: 'no',
        });
        this.anchor = this.mindarThree.addAnchor(0);
    }

    setOnTargetFound(value) {
        this.anchor.onTargetFound = value;
    }

    start() {
        return from(this.mindarThree.start());
    }
}