import { map } from "rxjs";
import Actor from "./actor";
import LoaderAudio from "./loader-audio";

export default class AudioActor extends Actor {
    constructor() {
        super();
        this._audioClip = null;
        this._loader = null;
    }
    get audioClip() {
        return this._audioClip;
    }
    addLoader(path) {
        this._loader = new LoaderAudio(path);
        this._loader.pipe(
            map(audioClip => {
                this._audioClip = audioClip;
                return this;
            }),
        );
    }
    addTo(world) {
        world.addAudio(this);
    }
}