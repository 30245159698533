import { map } from "rxjs";
import Vector3 from "./vector3";
import LoaderGLTF from "./loader-gltf";
import Actor from "./actor";

export default class ModelActor extends Actor {
    constructor(id) {
        super();
        this._id = id;
        this._gltf = null;
    }
    get id() {
        return this._id;
    }
    get gltf() {
        return this._gltf;
    }
    get position() {
        return this._transform.position;
    }
    setPosition(x, y, z) {
        this._transform.position = new Vector3(x, y, z);
    }
    get rotation() {
        return this._transform.rotation;
    }
    setRotation(x, y, z) {
        this._transform.rotation = new Vector3(
            this.deg2Rad(x), this.deg2Rad(y), this.deg2Rad(z)
        );
    }
    get scale() {
        return this._transform.scale;
    }
    set scale(value) {
        this._transform.scale = value;
    }
    addLoader(path, size) {
        this._loader = new LoaderGLTF(path, size);
        this._loader.pipe(
            map(gltf => {
                this._gltf = gltf;
                this._gltf.scene.userData = this.id;
                return this;
            }),
        );
    }
    addTo(world) {
        world.addActor(this);
    }
    handleTrigger() {
        console.log('Triggered!', this.id);
    }
    deg2Rad(value) {
        return value * Math.PI / 180;
    }
}