import { AudioLoader } from 'three';
import Loader from './loader';

export default class LoaderAudio extends Loader {
    constructor(path) {
        super(path);
    }
    load(path) {
        new AudioLoader().load(
            path,
            gltf => {
                this._onLoaded.next(gltf);
                this._onLoaded.complete();
            },
            xhr => {
                this._onLoadProgress.next({ id: this.id, progress: xhr.loaded / xhr.total * 100 });
                if (xhr.loaded >= xhr.total) {
                    this._onLoadProgress.complete();
                }
            },
            error => this._onLoaded.error(error)
        )
    }
}