import { forkJoin, Observable, Subject, tap } from "rxjs";

export default class ProgressTracker {
    constructor(loaders) {
        this.totalLoadersCount = loaders.length;

        this._onProgress = new Subject();
        this.onProgress$ = this._onProgress.asObservable();

        this.progressTrackingByID = loaders.reduce((carry, loader) => {
            carry[loader.id] = 0;
            return carry;
        }, {});

        this.onComplete$ = forkJoin(
            loaders.map(
                loader => loader.onLoadProgress$.pipe(
                    tap(
                        loadStep => {
                            this.progressTrackingByID[loadStep.id] = loadStep.progress;

                            const currentProgress = Object.values(this.progressTrackingByID).reduce(
                                (carry, current) => carry += current, 0
                            );
                            const totalProgress = currentProgress / this.totalLoadersCount;
                            this._onProgress.next(totalProgress);
                            if (totalProgress >= 100) {
                                this._onProgress.complete();
                            }
                        }
                    )
                )
            )
        );
    }

}