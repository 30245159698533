import ModelActor from "./model-actor";
import Transform from "./transform";
import Vector3 from "./vector3";

export default class ModelActorBuilder {
    constructor(id) {
        this._id = id;
        this._transform = new Transform();
        this._modelPath = null;
        this._modelSize = null;
        this._isTrigger = false;
    }
    static start(id) {
        return new ModelActorBuilder(id);
    }
    asTrigger() {
        this._isTrigger = true;
        return this;
    }
    withPosition(x, y, z) {
        this._transform.position = new Vector3(x, y, z);
        return this;
    }
    withRotation(x, y, z) {
        this._transform.rotation = new Vector3(x, y, z);
        return this;
    }
    withScale(value) {
        this._transform.scale = value;
        return this;
    }
    withLoader(path, size) {
        this._modelPath = path;
        this._modelSize = size;
        return this;
    }
    build() {
        const actor = new ModelActor(this._id);
        actor.setPosition(
            this._transform.position.x,
            this._transform.position.y,
            this._transform.position.z
        );
        actor.setRotation(
            this._transform.rotation.x,
            this._transform.rotation.y,
            this._transform.rotation.z
        );
        actor.scale = this._transform.scale;

        if (this._modelPath && this._modelSize) {
            actor.addLoader(
                this._modelPath,
                this._modelSize
            );
        }
        if (this._isTrigger) {
            actor.markAsTrigger();
        }
        return actor;
    }
}